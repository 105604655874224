<template>
  <section class="conOfArticles h-100 mb-5">
    <section
      class="conOfSections"
      v-if="listItems && listItems.length > 0"
    >
      <v-container>
        <v-row>
          <v-col md="9" cols="12">
            <div class="cardSecContainer">
              <div class="conofcard">
                <v-card class="mx-auto" tile elevation="0">
                  <div class="conOfCardImg">
                    <v-img
                      height="300"
                      :src="listItems[0].image"
                      alt="item of the Image"
                    ></v-img>
                  </div>
                  <div class="conOfSec">
                    <v-card-title class="cardTitle">
                      <div class="titleSec">
                        {{ listItems[0].title }}
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <div class="cardDesc">
                        {{ listItems[0].description }}
                      </div>
                    </v-card-text>
                  </div>
                </v-card>
              </div>
            </div>
          </v-col>
          <v-col md="3" cols="12">
            <div
              class="conOfFeaturedSec"
              v-if="listFeaturedItems && listFeaturedItems.length > 0"
            >
              <div class="headerSec mb-5 mt-3">Featured Articles</div>
              <div
                class="conofcard"
                v-for="(item, index) in listFeaturedItems"
                :key="index"
              >
                <router-link
                  :to="{ name: 'articleItem', params: { id: item.id } }"
                >
                  <v-card
                    class="mx-auto"
                    max-width="370"
                    height="275"
                    tile
                    elevation="0"
                  >
                    <div class="conOfCardImg">
                      <v-img
                        height="150"
                        :src="item.thumbnail"
                        alt="item of the Image"
                      ></v-img>
                    </div>
                    <div class="conOfSec">
                      <v-card-title class="cardTitle">
                        <div class="titleSec">
                          {{ item.title }}
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="cardDesc">
                          {{ item.description }}
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </router-link>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "Articles",
        disabled: false,
      },
    ],
    listItems: null,
    listFeaturedItems: null,
    isLoading: false,
  }),
  components: {
    EmptyState,
  },
  methods: {
    getListData() {
      this.listItems = [];
      this.isLoading = true;

      apiServices.get("article/" + this.$route.params.id).then((res) => {
        if (res) {
          this.listItems = res.data;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
    getListFraturesData() {
      this.listFeaturedItems = [];
      this.isLoading = true;

      apiServices.get("articles/featured").then((res) => {
        if (res) {
          this.listFeaturedItems = res.data;
        }
      });
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.getListFraturesData();
        this.getListData();
      },
      deep: true,
      immediate: true,
    },
  },
  // created() {
  //   this.getListFraturesData();
  //   this.getListData();
  // },
};
</script>
<style lang="scss" scoped>
@import "./_Articles-Item.scss";
</style>
